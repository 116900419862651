.pill-container {
    @apply relative flex items-center;

    .pill {
        @apply relative inline-flex h-8 items-center space-x-2 rounded-2xl transition duration-200 focus:outline-none focus:ring-2 focus:ring-white;

        .pill__label {
            @apply select-none truncate font-medium tracking-tight transition duration-200;
        }

        &.pill--button {
            &:hover {
                .pill__label {
                    @apply text-white;
                }
            }
        }

        &.pill--tiny {
            @apply h-5 rounded-md px-2;

            .pill__label {
                @apply text-xxs font-medium;
            }
        }

        &.pill--small {
            @apply px-3;

            .pill__label {
                @apply text-xs;
            }
        }

        &.pill--default {
            @apply px-4;

            .pill__label {
                @apply text-xs md:text-sm;
            }
        }

        &.pill--primary {
            @apply bg-bright-turquoise-500;

            .pill__label {
                @apply text-cerulean-950;
            }

            &.pill--button {
                @apply hover:bg-cerulean-500;

                .pill__label {
                    @apply text-cerulean-950;
                }
            }
        }

        &.pill--secondary {
            @apply bg-cerulean-500 hover:bg-cerulean-700;

            .pill__label {
                @apply text-cerulean-950;
            }

            &:hover {
                .pill__label {
                    @apply text-cerulean-950;
                }
            }
        }

        &.pill--black {
            @apply bg-slate-800 hover:bg-slate-950 focus:ring-black;

            .pill__label {
                @apply text-slate-400;
            }
        }

        &.pill--transparent {
            @apply bg-transparent p-0;

            &.pill--interactive {
                .pill__label {
                    @apply text-bright-turquoise-500 underline underline-offset-1 hover:text-bright-turquoise-600;
                }
            }
        }

        &.pill--red {
            @apply bg-red-100;

            .pill__label {
                @apply text-red-700;
            }
        }

        &.pill--orange {
            @apply bg-orange-100;

            .pill__label {
                @apply text-orange-700;
            }
        }

        &.pill--warning {
            @apply bg-yellow-100;

            .pill__label {
                @apply text-yellow-700;
            }
        }

        &.pill--success {
            @apply bg-green-100;

            .pill__label {
                @apply text-green-700;
            }
        }

        &.pill--gray {
            @apply bg-gray-200;

            .pill__label {
                @apply text-slate-500;
            }
        }

        &.pill--outline {
            @apply border border-bright-turquoise-500;

            .pill__label {
                @apply text-bright-turquoise-500;
            }

            &.pill--button {
                @apply hover:border-bright-turquoise-600;

                &:hover {
                    .pill__label {
                        @apply text-bright-turquoise-500;
                    }
                }
            }
        }

        &.pill--active {
            @apply bg-white text-stratos-950;

            .pill__label {
                @apply text-stratos-950;
            }

            &.pill--button {
                @apply hover:bg-white;

                &:hover {
                    .pill__label {
                        @apply text-stratos-950;
                    }
                }
            }
        }

        &.pill--busy {
            @apply bg-opacity-20 text-opacity-20;

            .pill__label {
                @apply text-opacity-20;
            }

            &.pill--button {
                @apply hover:bg-opacity-20;
            }
        }

        &.pill--has-right-arrow {
            @apply pr-1;
        }

        &.pill--disabled {
            @apply pointer-events-none;
        }
    }
}
