.more {
    @apply w-0 h-full flex items-center transition duration-200;

    .__list {
        @apply flex;

        .__children {
            @apply flex-none flex flex-row items-center overflow-hidden;
        }

        .__trigger {
            @apply h-8 w-8 flex items-center justify-end pr-1 relative z-50;

            .__trigger-label {
                @apply text-bright-turquoise-500 text-xs font-semibold tracking-tighter;
            }

            &.--full-width {
                @apply w-full;
            }
        }

        .__menu {
            @apply hidden top-1/2 left-1/2 fixed flex-none bg-white overflow-hidden min-w-56 rounded-md sm:min-w-32 drop-shadow mt-1 opacity-0 transition-opacity duration-300 -translate-x-1/2 -translate-y-1/2 transform-gpu sm:translate-x-0 sm:translate-y-0;
            z-index: 999;

            .__menu-title {
                @apply font-semibold text-center py-2 sm:hidden block sm:text-sm border-b border-b-slate-200;
            }

            .__menu-container {
                @apply max-h-96 sm:max-h-36 overflow-y-auto;
            }

            .__more-menu-item {
                @apply block w-full hover:bg-slate-100 border-b last:border-none border-slate-50 transition duration-100;

                .__label {
                    @apply block text-left px-2.5 py-1.5 sm:px-1.5 sm:py-1 text-sm sm:text-xxs whitespace-nowrap capitalize font-medium truncate;
                }

                &.__close {
                    @apply bg-slate-50 hover:bg-slate-100 block sm:hidden;

                    .__label {
                        @apply text-center;
                    }
                }
            }

            &.--open {
                @apply opacity-100;
            }
        }
    }

    &.--open {
        .__menu {
            @apply block;
        }
    }

    &.--calculating {
        @apply opacity-0 -ml-160;
    }
}
